<template>
  <v-dialog v-model="data.dialog" persistent max-width="600px">
    <v-card v-if="loading" :color="isDelete ? 'error' : 'info'" dark>
      <v-card-text>
        <span class="font-weight-bold text-white">{{ $t('loading') }}</span>
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
    <v-card v-if="!loading">
      <v-card-title
        :class="{'form-danger': isDelete, 'form-info': isUpdate, 'form-primary': isCreate}"
      >
        <span v-if="isCreate" class="">{{ $('create') }}</span>
        <span v-if="isUpdate" class="">{{ $('update') }}</span>
        <span v-if="isDelete" class="">{{ $('delete') }}</span>
      </v-card-title>
      <v-card-text>
        <h2 v-if="isDelete && check" class="my-3">
          {{ $('delete_msg', [check.name]) }}
        </h2>

        <v-form
          v-if="!isDelete"
          ref="form"
          v-model="valid"
        >
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                <v-text-field
                  v-model="form.name"
                  v-max-length="200"
                  :label="$('name')"
                  :rules="rules.name"
                  required
                  outlined
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="12"
              >
                <v-text-field
                  v-model="form.address"
                  v-max-length="200"
                  :label="`العنوان`"
                  :rules="rules.name"
                  required
                  outlined
                ></v-text-field>
              </v-col>

              <v-col
                cols="6"
                sm="6"
              >
                <v-select
                  v-model="form.type"
                  :items="CheckPointType"
                  item-text="state"
                  :rules="rules.type"
                  item-value="value"
                  label=" نوع السيطرة "
                  outlined
                ></v-select>
              </v-col>
              <v-col
                cols="6"
                sm="6"
              >
                <v-select
                  v-model="form.active"
                  :items="CheckStates"
                  item-text="state"
                  :rules="rules.active"
                  item-value="value"
                  label="حالة السيطرة"
                  outlined
                ></v-select>
              </v-col>

              <v-col
                cols="12"
                sm="12"
              >
                <v-select
                  v-model="form.funerals"
                  :items="FuneralsStates"
                  :rules="rules.funerals"
                  item-text="state"
                  item-value="value"
                  label="الجنائز"
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="light"
          outlined
          @click="dialogModal(false)"
        >
          {{ $t('form.cancel') }}
        </v-btn>
        <v-btn
          v-if="isCreate"
          color="primary"
          :loading="submitLoading"
          :disabled="!valid || submitLoading"
          @click="submit"
        >
          {{ $t('form.create') }}
        </v-btn>
        <v-btn
          v-if="isUpdate"
          color="info"
          :loading="submitLoading"
          :disabled="!valid || submitLoading"
          @click="submit"
        >
          {{ $t('form.save') }}
        </v-btn>
        <v-btn
          v-if="isDelete"
          color="error"
          :loading="submitLoading"
          :disabled="submitLoading"
          @click="submit"
        >
          {{ $t('form.delete') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from '@axios'
import toast from '@/store/toast'

export default {
  name: 'CheckPointDialog',
  props: {
    data: {
      type: Object,
      default: () => ({
        dialog: false,
        id: null,
        type: 'create',
      }),
    },
    dialogModal: {
      type: Function,
      default: state => {
        this.data.dialog = state
      },
    },
    translationKey: {
      type: String,
      default: () => '',
    },
  },
  emits: ['update:table'],
  setup() {
    return {
    }
  },
  data: () => ({
    loading: true,
    submitLoading: false,
    valid: false,
    check: null,
    form: {
      name: '',
      address: '',
      active: '',
      type: '',
      funerals: '',
    },

    CheckStates: [
      { state: 'فعال', value: 1 },
      { state: 'غير فعال', value: 0 },
    ],
    FuneralsStates: [
      { state: 'يسمح', value: 1 },
      { state: 'لا يسمح', value: 0 },
    ],
    CheckPointType: [
      { state: 'داخلية', value: 0 },
      { state: 'خارجية', value: 1 },
    ],
  }),
  computed: {
    isCreate() {
      return this.data.type === 'create'
    },
    isUpdate() {
      return this.data.type === 'update'
    },
    isDelete() {
      return this.data.type === 'delete'
    },

    rules() {
      const rules = {};
      rules.name = [
        v => !!v || this.$t('form.required', [this.$t(`${this.translationKey}.modal.name`)]),
      ];
      rules.address = [
        v => !!v || this.$t('form.required', [this.$t(`${this.translationKey}.modal.address`)]),
      ];
      rules.active = [
        v => v !== null || this.$t('form.required', [this.$t(`${this.translationKey}.modal.active`)]),
      ];
      rules.funerals = [
        v => v !== null || this.$t('form.required', [this.$t(`${this.translationKey}.modal.funerals`)]),
      ];
      rules.type = [
        v => v !== null || this.$t('form.type', [this.$t(`${this.translationKey}.modal.type`)]),
      ];

      return rules;
    },
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(val) {
        try {
          this.loading = true;
          if (val.id) {
            const checkpoints = await axios.get(`/checkpoints/${val.id}`);
            this.form = checkpoints.data.data;
            this.check = checkpoints.data.data
          }
          if (!val.dialog) {
            this.resetForm();
          }
        } catch (error) {
          //   await toast.dispatch('error', error.response ? error.response.data.message : error.message)
        } finally {
          this.loading = false;
        }
      },
    },
  },
  async mounted() {

  },
  methods: {
    async submit() {
      try {
        this.submitLoading = true;
        if (this.isDelete) {
          await axios.delete(`/checkpoints/delete/${this.data.id}`);
          await toast.dispatch('success', this.$t('form.success.delete'))
          this.dialogModal(false)
          this.$emit('update:table')
        }

        if (this.$refs.form.validate()) {
          this.$emit('update:table')

          if (this.isCreate) {
            await axios.post('/checkpoints/create', this.form);
            await toast.dispatch('success', this.$t('form.success.create'))
          } else if (this.isUpdate) {
            await axios.post(`/checkpoints/edit/${this.data.id}`, this.form);
            await toast.dispatch('success', this.$t('form.success.update'))
          }
          this.$emit('update:table')
          this.dialogModal(false)
        }
      } catch (error) {
        //   await toast.dispatch('error', error.response ? error.response.data.message : error.message)
      } finally {
        this.submitLoading = false;
      }
    },
    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.form = {
        name: '',
        address: '',
        active: '',
        funerals: '',
        type: '',
      };
    },
    $(key, parameters = null) {
      return this.$t(`${this.translationKey}.modal.${key}`, parameters);
    },
  },
}
</script>

<style scoped>

</style>
